import { graphql, Link } from "gatsby";
import Img from "gatsby-image";
import * as React from "react";
import Layout from "../components/layout";
import theme from "../styles/theme";

const PrivacyPolicy = ({ data }) => {
  return (
    <Layout
      location="/privacy-policy"
      seo={data.page.seoMetaTags}
      logo={data.global.logo}
    >
      <main
        css={`
          margin: 0 auto;
          padding-bottom: 100px;
          width: 100%;
          max-width: 1600px;
          box-sizing: border-box;
          padding-top: 100px;
          ${theme.laptop} {
            padding-top: 50px;
          }
          ${theme.tablet} {
            padding-bottom: 0;
          }
          h1 {
            font-size: 72px;
            line-height: 110%;
          }
          .excerpt {
            font-size: 28px;
            font-weight: 900;
            line-height: 110%;
            color: ${theme.pink};
          }
          .imgHero {
            width: 100%;
          }
          .contentWrapper {
            max-width: 900px;
          }
          .content {
            iframe {
              width: 100%;
            }
            p, li {
              font-size: 18px;
              line-height: 1.2;
              margin-top: 26px;
            }
            h2 {
              color: ${theme.pink};
              font-size: 28px;
              margin-top: 40px;
              margin-bottom: 0;
            }
            h3 {
              margin-top: 20px;
              font-size: 24px;
              margin-bottom: 0;
              font-weight: 900;
            }
            blockquote {
              font-weight: 900;
              margin-left: 0;
            }
            img {
              max-width: 100%;
            }
            a {
              color: ${theme.pink};
              opacity: 0.7;
              font-weight: 700;
              &:hover {
                opacity: 1;
                transition: opacity 0.2s;
              }
            }
          }
          ${theme.mobile} {
            h1 {
              font-size: 32px;
            }
            .excerpt {
              font-size: 18px;
            }
            p, li {
              font-size: 16px;
            }
          }
        `}
      >
        <section
          css={`
            padding-top: 100px;
            padding-bottom: 100px;
            flex-direction: column;
            ${theme.mobile} {
              padding-top: 40px;
              padding-bottom: 40px;
            }
          `}
        >
         
          <div className="contentWrapper">
            <h1>{data.page.title}</h1>            
            <div
              className="content"
              dangerouslySetInnerHTML={{ __html: data.page.content }}
            />
          </div>
        </section>        
      </main>
    </Layout>
  );
};

export default PrivacyPolicy;


export const query = graphql`
  query GdprQuery {
    page: datoCmsGdpr {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      title
      content
    }
    global: datoCmsGlobal {
      logo {
        fluid(maxWidth: 700) {
          ...GatsbyDatoCmsFluid
        }
        alt
      }
    }
  }
`;
